.stepper_container{
  overflow-y: hidden;
  height: 100%;
}
.mat-stepper-horizontal{
  &.stepperfixedheight-horizontal{
    background-color: transparent;
    overflow-y: hidden;
    height: 100%;
    //position: relative;
    display: grid;
    grid-template-areas: 'header'
                         'content';
    grid-template-rows: auto 1fr;
    margin-bottom: 10rem;
    box-sizing: border-box;
    //border: 5px solid rebeccapurple;
    .mat-horizontal-stepper-header-container {
      grid-area: header;
      //background-color: pink;
      height: 72px;
      .mat-horizontal-stepper-header {
        //background-color: red;

        .mat-step-header-label {
          color: #fff;
        }
      }

      .mat-step-icon{
        
        width:24px;
        height: 24px;

        .mat-step-icon-content{
          display: flex;
          align-items: center;
          justify-content: center;
          width:24px;
        }
        span {
            color: white !important;
            text-align: center;
            
            width:24px;
          }
        &.mat-step-icon-selected{

            // background-color: #B01352 !important;
            // border: 2px solid #B01352 !important;
            background-color: #30373A  !important;
            border: 2px solid #30373A !important;
          
        }
      }

      .mat-step-label{
        font-size: 17px;
        line-height: 1;
        &.mat-step-label-text{
          color: #999999;
        }
      }
    }

    .mat-horizontal-content-container{
      grid-area: content;
      //background-color: brown;
      position: relative;
      overflow-y: hidden;
      display: block;
      padding:0px;

      .mat-horizontal-stepper-content{


        display: grid;
        grid-template-areas: 'content'
                             'footer';
        grid-template-rows: 1fr auto;
        width:100%;

        overflow-y: hidden;
        //background-color: #2BD402;
          .stepper-content{
            grid-area: content;

            min-height: 100%;
            overflow-y: auto;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: stretch;
          }
          .stepper-footer{
            grid-area: footer;
            width: 100%;

            button {
              font-size: 16px;
              height: 40px;
              min-width:150px;
              line-height: 1;

              border-radius: 50px;

              &.indietro{
                background-color: #BBBBBB;
                border: none;
                color: white;
              }

              &.active {
                @apply bg-mc-uniform;
                
              }

            }
          }
      }
    }





  }
}
.mat-horizontal-stepper-content {
  height: 100%;
}



//.mat-step-icon-selected {
//  background-color: #B01352 !important;
//  border: 2px solid #B01352 !important;
//}
//
//.mat-step-icon-selected span {
//  color: white !important;
//}
