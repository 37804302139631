$mat-icon-width: 20px;
$border-radius: 24px;
$min-height: 36px; //48px
$label-color: #999999;
$label-font-size: 16px;
$prefix-suffix-color: #404040;
$greyBg: rgba(255, 255, 255, 0.4);
$greyFg: white;


mat-form-field.styledmobilitycompanyv15 {
  /* "fill" appearance */
  &.mat-mdc-form-field.mat-form-field-appearance-fill {
    &.bgGray{
      .mat-mdc-text-field-wrapper{
        background-color: $greyBg !important;
        border-color: $greyBg !important;
        .mat-mdc-form-field-flex .mat-mdc-form-field-infix .mat-mdc-floating-label{
          color: black !important;
        }

        .mat-mdc-floating-label{
          color: black !important;
          mat-label{
            @apply text-black;
          }
        }
      }
      input{

        color: $greyFg;
        &::placeholder {
          color: darken($greyFg, 30%) !important;
        }
      }

      mat-icon{
        color: $greyFg;
      }

      mat-label{
        @apply text-black;
      }
      .mdc-floating-label{
        @apply text-black ;
      }






      .mat-mdc-select-value-text{
        @apply font-ubuntu font-semibold;
      }

      .mat-mdc-select-placeholder{
        @apply font-ubuntu font-semibold;
        color: darken($greyFg, 30%) !important;
      }
      .mat-mdc-select-value{
        @apply font-ubuntu font-semibold;
        color: $greyFg;
      }
      .mat-mdc-select-arrow-wrapper{
        @apply font-ubuntu font-semibold;
        color: darken($greyFg, 30%) !important;
        .mat-mdc-select-arrow{
          color: darken($greyFg, 30%) !important;
        }
      }

    }

    &.noSubscript{
      .mat-mdc-form-field-subscript-wrapper{
        display: none;
      }
    }

    /* Disabled */
    &.mat-form-field-disabled {
      opacity: 0.7 !important;
    }

    /* Invalid */
    &.mat-form-field-invalid {

      /* Border color */
      .mat-mdc-text-field-wrapper {
        //@apply border-warn dark:border-warn #{'!important'};
      }

      /* Select */
      .mat-mdc-select {

        /* Placeholder color */
        .mat-mdc-select-placeholder {
          //@apply text-warn #{'!important'};
        }
      }
    }

    /* Hover */
    &:hover {

      .mat-mdc-form-field-focus-overlay {
        opacity: 0 !important;
      }
    }

    /* Focused */
    &.mat-focused {

      .mat-mdc-form-field-focus-overlay {
        opacity: 0 !important;
      }
    }

    /* Focused and valid fields */
    &.mat-focused:not(.mat-form-field-invalid) {

      /* Border color */
      .mat-mdc-text-field-wrapper {
        //@apply border-primary dark:border-primary #{'!important'};
      }
    }

    /* Remove the default arrow for native select */
    &.mat-mdc-form-field-type-mat-native-select {

      .mat-mdc-form-field-infix {

        select {
          top: auto;
          margin-top: 0;
          margin-bottom: 0;
          padding-top: 0;
          padding-right: 18px;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%2364748B' viewBox='0 0 24 24'%3E%3Cpath d='M7 10l5 5 5-5H7z'/%3E%3C/svg%3E");
          background-repeat: no-repeat;
          background-position: right -7px center;
          background-size: 24px;

          .dark & {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%2397a6ba' viewBox='0 0 24 24'%3E%3Cpath d='M7 10l5 5 5-5H7z'/%3E%3C/svg%3E");
          }
        }

        &:after {
          display: none;
        }
      }
    }

    /* Default style tweaks and enhancements */
    .mat-mdc-text-field-wrapper {
      height: 40px;
      padding: 0;
      border-radius: 40px;
      border-width: 1px;
      border-style: none;
      box-shadow: 0px 3px 6px #00000029;
      @apply  bg-white border-gray-300  #{'!important'}; //dark:bg-black dark:bg-opacity-5 dark:border-gray-500

      /* Adjust the top spacing and overflow when mat-label present */
      &:not(.mdc-text-field--no-label) {
        margin-top: 24px;
        overflow: visible;
      }

      .mat-mdc-form-field-focus-overlay {
        border-radius: 6px;
      }

      /* Form field */
      .mat-mdc-form-field-flex {
        position: relative;
        display: flex;
        align-items: stretch;
        border-radius: 6px;
        padding: 0 16px;
        height: 40px;

        .mat-mdc-form-field-icon-prefix,
        .mat-mdc-form-field-text-prefix {
          padding: 0 !important;
          height: 40px;

          > .mat-icon {
            margin-right: 12px;
            padding: 0 !important;
          }

          > .mat-mdc-icon-button {
            margin: 0 4px 0 -10px;
          }

          > .mat-mdc-select {
            margin-right: 10px;
          }

          > .mat-datepicker-toggle {
            margin-left: -8px;
          }
        }

        .mat-mdc-form-field-icon-suffix,
        .mat-mdc-form-field-text-suffix {
          padding: 0 !important;

          > .mat-icon {
            margin-left: 12px;
            padding: 0 !important;
          }

          > .mat-mdc-icon-button {
            margin: 0 -10px 0 4px;
          }

          > .mat-mdc-select {
            margin-left: 10px;
          }

          > .mat-datepicker-toggle {
            margin-right: -16px;
          }
        }

        .mat-mdc-form-field-icon-prefix,
        .mat-mdc-form-field-text-prefix,
        .mat-mdc-form-field-icon-suffix,
        .mat-mdc-form-field-text-suffix {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          //@apply text-hint #{'!important'};

          .mat-mdc-icon-button {
            width: 40px;
            min-width: 40px;
            height: 40px;
            min-height: 40px;
            margin:auto;
            padding: 0;
            svg{
              margin-left: auto;
              margin-right: auto;
            }
            .mat-mdc-button-touch-target{
              width: 40px;
              min-width: 40px;
              height: 40px;
              min-height: 40px;
            }
          }


          .mat-icon,
          .mat-mdc-icon-button:not([disabled]),
          .mat-mdc-select-value {
            //@apply text-hint;
          }

          /* Datepicker default icon size */
          .mat-datepicker-toggle-default-icon {
            //@apply icon-size-6;
          }

          /* Make mat-select usable as prefix and suffix */
          .mat-mdc-select {
            display: flex;
            align-items: center;

            &:focus {

              .mat-mdc-select-trigger {

                .mat-mdc-select-value {
                  @apply text-dark  #{'!important'};
                  //@apply text-primary #{'!important'};
                }

                .mat-mdc-select-arrow-wrapper {

                  .mat-mdc-select-arrow {
                    border-top-color: var(--fuse-primary) !important;
                  }
                }
              }
            }

            .mat-mdc-select-trigger {
              display: flex;
              align-items: center;

              .mat-mdc-select-value {
                display: flex;
                max-width: none;
                @apply text-dark  #{'!important'};
                mat-mdc-select-trigger {

                  .mat-icon {
                    margin: 0 !important;
                  }
                }
              }

              .mat-mdc-select-arrow-wrapper {
                display: flex;
                align-items: center;
                transform: none;
                margin-left: 4px;

                .mat-mdc-select-arrow {
                  min-height: 0;
                  @apply text-gray-500 dark:text-gray-400 #{'!important'};
                }
              }
            }
          }
        }

        /* Infix */
        .mat-mdc-form-field-infix {
          position: static;
          display: flex;
          align-items: center;
          width: 88px;
          min-height: 40px;
          padding: 0;
          border: 0;

          /* Floating label - disable floating action */
          .mat-mdc-floating-label {
            top: -25px !important;
            left: 0 !important;
            width: 100% !important;
            transform: none !important;
            pointer-events: auto;
            font-weight: 500;
            //@apply text-default #{'!important'};
          }

          /* Textarea */
          textarea.mat-mdc-input-element {
            margin: 12px 0;
            padding: 0 6px 0 0;
          }

          /* Chips */
          .mat-mdc-chip-set {
            width: 100%;
            margin: 0 -8px;
          }
        }


        input{
          font-size: 19px;
        }
      }

      /* Remove the underline */
      .mdc-line-ripple {
        display: none;
      }
    }

    /* Subscript tweaks */
    .mat-mdc-form-field-subscript-wrapper {
      font-size: 12px;
      font-weight: 500;

      .mat-mdc-form-field-hint-wrapper,
      .mat-mdc-form-field-error-wrapper {
        padding: 0;
      }

      .mat-mdc-form-field-hint {
        //@apply text-hint #{'!important'};
      }
    }

    /* Adds better alignment for textarea inputs */
    &:has(textarea.mat-mdc-input-element) {

      .mat-mdc-text-field-wrapper {

        .mat-mdc-form-field-flex {

          .mat-mdc-form-field-icon-prefix,
          .mat-mdc-form-field-text-prefix,
          .mat-mdc-form-field-icon-suffix,
          .mat-mdc-form-field-text-suffix {
            align-self: flex-start;
            padding-top: 14px !important;
          }
        }
      }
    }






  }

  .mat-mdc-select-value-text{
    @apply font-ubuntu font-semibold;
  }

  .mat-mdc-select-placeholder{
    @apply font-ubuntu font-semibold;
  }
  .mat-mdc-select-value{
    @apply font-ubuntu font-semibold;
  }
  .mat-mdc-select-arrow-wrapper{
    @apply font-ubuntu font-semibold;
    .mat-mdc-select-arrow{
    }
  }

}

mat-form-field.mat-mdc-form-field {
  @apply font-ubuntu;
}

mat-form-field {
  .mat-mdc-select-value {

    color: #30373A;
    
  }
  .mat-mdc-select-placeholder{
    font-size: 17px;
    font-weight: 500;
    color: #999999;
    
  }
}


.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after, .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after{
  color: #30373A;
}

.mdc-list-item--selected{
  background-color: #E6E6E6;
  color: #30373A;
  font-weight: bold;
  
}