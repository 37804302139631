.mat-menu-panel{
  @apply font-ubuntu;
  background-color: white;
  color: black;


  button.mat-menu-item:hover{
    background-color: #d3d3d3;
  }
}

.mat-mdc-menu-content, .mat-mdc-menu-content .mat-mdc-menu-item .mdc-list-item__primary-text{
  @apply font-ubuntu;
}
