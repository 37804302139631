

.mdc-dialog.mat-mdc-dialog-container{
  //@apply w-[90vw] xl:w-[70vw] h-[90vh] xl:h-[70vh];
}


.dialog .mat-mdc-dialog-container{
  @apply rounded-2xl;// bg-[#f2f2f2];
}


.mat-mdc-dialog-container .mdc-dialog__surface{
  @apply rounded-2xl  p-5 #{!important}; //bg-[#f2f2f2]
}


.mat-mdc-dialog-container {
  --mdc-dialog-container-color: #f2f2f2;
  --mdc-dialog-with-divider-divider-color: rgba(0, 0, 0, 0.12);
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
  --mdc-dialog-subhead-font: Roboto, sans-serif;
  --mdc-dialog-subhead-line-height: 32px;
  --mdc-dialog-subhead-size: 20px;
  --mdc-dialog-subhead-weight: 500;
  --mdc-dialog-subhead-tracking: 0.0125em;
  --mdc-dialog-supporting-text-font: Roboto, sans-serif;
  --mdc-dialog-supporting-text-line-height: 24px;
  --mdc-dialog-supporting-text-size: 16px;
  --mdc-dialog-supporting-text-weight: 400;
  --mdc-dialog-supporting-text-tracking: 0.03125em;
}
